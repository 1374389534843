/* eslint-disable */
import * as z from 'zod'

import {
  Struct,
  Entity,
  Meta,
  recase,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { BundleLoader, GazelleEntityFactory, ValueOf } from '@apsys/gazelle'
import { ReffedEntity as ReffableEntity, WithRef } from '@apsys/gazelle'

import * as VixenCore from './vixen-core'

export const meta: Meta = {
  name: 'Vixen Assets',
  moduleName: 'vixen-assets',
  version: '0.10.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- asset-group ---
export interface AssetGroupSchema extends VixenCore.NamedSchema {
  assets: Array<GazelleRefSchema>
  'external-id'?: string | null
}

export interface IAssetGroup extends VixenCore.INamed {
  assets: Array<GazelleRef<IAsset>>
  externalId?: string
}

// --- asset ---
export interface AssetSchema {
  url: string
  'content-type': string
  language?: string | null
  copyright?: string | null
  'distribution-control': 'unrestricted' | 'onsite' | 'admin-only'
  'external-id'?: string | null
}

export interface IAsset {
  url: string
  contentType: string
  language?: string
  copyright?: string
  distributionControl: 'unrestricted' | 'onsite' | 'admin-only'
  externalId?: string
}

// --- spatial-asset ---
export interface SpatialAssetSchema extends AssetSchema {
  width: number
  height: number
}

export interface ISpatialAsset extends IAsset {
  width: number
  height: number
}

// --- temporal-asset ---
export interface TemporalAssetSchema extends AssetSchema {
  duration?: number | null
  'subtitle-languages': Array<string>
}

export interface ITemporalAsset extends IAsset {
  duration?: number
  subtitleLanguages: Array<string>
}

// --- image-asset ---
export interface ImageAssetSchema extends AssetSchema {}

export interface IImageAsset extends IAsset {}

// === structs ===
export interface PointSchema {
  x: number
  y: number
}

export interface IPoint {
  x: number
  y: number
}

type PointSchemaType = z.Schema<Point, z.ZodTypeDef, PointSchema>

export const PointSchema = z
  .lazy(() =>
    z.object({
      x: z.number(),
      y: z.number(),
    }),
  )
  .transform(value => new Point(recase(value))) satisfies PointSchemaType

export class Point extends Struct<PointSchema> implements IPoint {
  static readonly typename = 'point'
  static readonly schema: PointSchemaType = PointSchema
  static readonly parse = PointSchema.parse
  static readonly fields = ['x', 'y']

  readonly typename = 'point'

  x!: number
  y!: number

  constructor(attrs: IPoint) {
    super(attrs)
  }
}

export interface BoxSchema {
  'top-left': PointSchema
  'bottom-right': PointSchema
}

export interface IBox {
  topLeft: Point
  bottomRight: Point
}

type BoxSchemaType = z.Schema<Box, z.ZodTypeDef, BoxSchema>

export const BoxSchema = z
  .lazy(() =>
    z.object({
      'top-left': PointSchema,
      'bottom-right': PointSchema,
    }),
  )
  .transform(value => new Box(recase(value))) satisfies BoxSchemaType

export class Box extends Struct<BoxSchema> implements IBox {
  static readonly typename = 'box'
  static readonly schema: BoxSchemaType = BoxSchema
  static readonly parse = BoxSchema.parse
  static readonly fields = ['top-left', 'bottom-right']

  readonly typename = 'box'

  topLeft!: Point
  bottomRight!: Point

  constructor(attrs: IBox) {
    super(attrs)
  }
}

// === entities ===
export interface PictureSchema
  extends AssetGroupSchema,
    VixenCore.RoutableSchema {
  assets: Array<GazelleRefSchema>
  'alt-text': Record<string, string>
}

export interface IPicture extends IAssetGroup, VixenCore.IRoutable {
  assets: Array<GazelleRef<IImageAsset>>
  altText: Record<string, string>
}

type PictureSchemaType = z.Schema<Picture, z.ZodTypeDef, PictureSchema>

export const PictureSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      assets: z.array(GazelleRefSchema),
      'external-id': z.string().nullish(),
      'alt-text': z.record(z.string()),
    }),
  )
  .transform(value => new Picture(recase(value))) satisfies PictureSchemaType

export class Picture extends Entity<PictureSchema> implements IPicture {
  static readonly typename = 'picture'
  static readonly schema: PictureSchemaType = PictureSchema
  static readonly parse = PictureSchema.parse
  static readonly fields = ['ref', 'name', 'assets', 'external-id', 'alt-text']

  readonly typename = 'picture'

  ref!: GazelleRef<Picture>
  name!: Record<string, string>
  assets!: Array<GazelleRef<IImageAsset>>
  externalId?: string
  altText!: Record<string, string>

  constructor(attrs: IPicture & WithRef<Picture>) {
    super(attrs)
  }
}

export interface VideoSchema
  extends AssetGroupSchema,
    VixenCore.RoutableSchema {
  assets: Array<GazelleRefSchema>
  'preview-image': Array<GazelleRefSchema>
  transcript?: GazelleRefSchema | null
}

export interface IVideo extends IAssetGroup, VixenCore.IRoutable {
  assets: Array<GazelleRef<VideoAsset>>
  previewImage: Array<GazelleRef<IImageAsset>>
  transcript?: GazelleRef<VixenCore.IContent>
}

type VideoSchemaType = z.Schema<Video, z.ZodTypeDef, VideoSchema>

export const VideoSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      assets: z.array(GazelleRefSchema),
      'external-id': z.string().nullish(),
      'preview-image': z.array(GazelleRefSchema),
      transcript: GazelleRefSchema.nullish(),
    }),
  )
  .transform(value => new Video(recase(value))) satisfies VideoSchemaType

export class Video extends Entity<VideoSchema> implements IVideo {
  static readonly typename = 'video'
  static readonly schema: VideoSchemaType = VideoSchema
  static readonly parse = VideoSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'assets',
    'external-id',
    'preview-image',
    'transcript',
  ]

  readonly typename = 'video'

  ref!: GazelleRef<Video>
  name!: Record<string, string>
  assets!: Array<GazelleRef<VideoAsset>>
  externalId?: string
  previewImage!: Array<GazelleRef<IImageAsset>>
  transcript?: GazelleRef<VixenCore.IContent>

  constructor(attrs: IVideo & WithRef<Video>) {
    super(attrs)
  }
}

export interface AudioSchema extends AssetGroupSchema {
  assets: Array<GazelleRefSchema>
  transcript?: GazelleRefSchema | null
}

export interface IAudio extends IAssetGroup {
  assets: Array<GazelleRef<AudioAsset>>
  transcript?: GazelleRef<VixenCore.IContent>
}

type AudioSchemaType = z.Schema<Audio, z.ZodTypeDef, AudioSchema>

export const AudioSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      name: z.record(z.string()),
      assets: z.array(GazelleRefSchema),
      'external-id': z.string().nullish(),
      transcript: GazelleRefSchema.nullish(),
    }),
  )
  .transform(value => new Audio(recase(value))) satisfies AudioSchemaType

export class Audio extends Entity<AudioSchema> implements IAudio {
  static readonly typename = 'audio'
  static readonly schema: AudioSchemaType = AudioSchema
  static readonly parse = AudioSchema.parse
  static readonly fields = [
    'ref',
    'name',
    'assets',
    'external-id',
    'transcript',
  ]

  readonly typename = 'audio'

  ref!: GazelleRef<Audio>
  name!: Record<string, string>
  assets!: Array<GazelleRef<AudioAsset>>
  externalId?: string
  transcript?: GazelleRef<VixenCore.IContent>

  constructor(attrs: IAudio & WithRef<Audio>) {
    super(attrs)
  }
}

export interface RasterImageAssetSchema
  extends SpatialAssetSchema,
    ImageAssetSchema {
  'focus-area'?: BoxSchema | null
}

export interface IRasterImageAsset extends ISpatialAsset, IImageAsset {
  focusArea?: Box
}

type RasterImageAssetSchemaType = z.Schema<
  RasterImageAsset,
  z.ZodTypeDef,
  RasterImageAssetSchema
>

export const RasterImageAssetSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
      'content-type': z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      'distribution-control': z.enum(['unrestricted', 'onsite', 'admin-only']),
      'external-id': z.string().nullish(),
      width: z.number(),
      height: z.number(),
      'focus-area': BoxSchema.nullish(),
    }),
  )
  .transform(
    value => new RasterImageAsset(recase(value)),
  ) satisfies RasterImageAssetSchemaType

export class RasterImageAsset
  extends Entity<RasterImageAssetSchema>
  implements IRasterImageAsset
{
  static readonly typename = 'raster-image-asset'
  static readonly schema: RasterImageAssetSchemaType = RasterImageAssetSchema
  static readonly parse = RasterImageAssetSchema.parse
  static readonly fields = [
    'ref',
    'url',
    'content-type',
    'language',
    'copyright',
    'distribution-control',
    'external-id',
    'width',
    'height',
    'focus-area',
  ]

  readonly typename = 'raster-image-asset'

  ref!: GazelleRef<RasterImageAsset>
  url!: string
  contentType!: string
  language?: string
  copyright?: string
  distributionControl!: 'unrestricted' | 'onsite' | 'admin-only'
  externalId?: string
  width!: number
  height!: number
  focusArea?: Box

  constructor(attrs: IRasterImageAsset & WithRef<RasterImageAsset>) {
    super(attrs)
  }
}

export interface VectorImageAssetSchema
  extends SpatialAssetSchema,
    ImageAssetSchema {}

export interface IVectorImageAsset extends ISpatialAsset, IImageAsset {}

type VectorImageAssetSchemaType = z.Schema<
  VectorImageAsset,
  z.ZodTypeDef,
  VectorImageAssetSchema
>

export const VectorImageAssetSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
      'content-type': z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      'distribution-control': z.enum(['unrestricted', 'onsite', 'admin-only']),
      'external-id': z.string().nullish(),
      width: z.number(),
      height: z.number(),
    }),
  )
  .transform(
    value => new VectorImageAsset(recase(value)),
  ) satisfies VectorImageAssetSchemaType

export class VectorImageAsset
  extends Entity<VectorImageAssetSchema>
  implements IVectorImageAsset
{
  static readonly typename = 'vector-image-asset'
  static readonly schema: VectorImageAssetSchemaType = VectorImageAssetSchema
  static readonly parse = VectorImageAssetSchema.parse
  static readonly fields = [
    'ref',
    'url',
    'content-type',
    'language',
    'copyright',
    'distribution-control',
    'external-id',
    'width',
    'height',
  ]

  readonly typename = 'vector-image-asset'

  ref!: GazelleRef<VectorImageAsset>
  url!: string
  contentType!: string
  language?: string
  copyright?: string
  distributionControl!: 'unrestricted' | 'onsite' | 'admin-only'
  externalId?: string
  width!: number
  height!: number

  constructor(attrs: IVectorImageAsset & WithRef<VectorImageAsset>) {
    super(attrs)
  }
}

export interface VideoAssetSchema
  extends SpatialAssetSchema,
    TemporalAssetSchema,
    VixenCore.PlayableSchema {}

export interface IVideoAsset
  extends ISpatialAsset,
    ITemporalAsset,
    VixenCore.IPlayable {}

type VideoAssetSchemaType = z.Schema<
  VideoAsset,
  z.ZodTypeDef,
  VideoAssetSchema
>

export const VideoAssetSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
      'content-type': z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      'distribution-control': z.enum(['unrestricted', 'onsite', 'admin-only']),
      'external-id': z.string().nullish(),
      duration: z.number().nullish(),
      'subtitle-languages': z.array(z.string()),
      width: z.number(),
      height: z.number(),
    }),
  )
  .transform(
    value => new VideoAsset(recase(value)),
  ) satisfies VideoAssetSchemaType

export class VideoAsset
  extends Entity<VideoAssetSchema>
  implements IVideoAsset
{
  static readonly typename = 'video-asset'
  static readonly schema: VideoAssetSchemaType = VideoAssetSchema
  static readonly parse = VideoAssetSchema.parse
  static readonly fields = [
    'ref',
    'url',
    'content-type',
    'language',
    'copyright',
    'distribution-control',
    'external-id',
    'duration',
    'subtitle-languages',
    'width',
    'height',
  ]

  readonly typename = 'video-asset'

  ref!: GazelleRef<VideoAsset>
  url!: string
  contentType!: string
  language?: string
  copyright?: string
  distributionControl!: 'unrestricted' | 'onsite' | 'admin-only'
  externalId?: string
  duration?: number
  subtitleLanguages!: Array<string>
  width!: number
  height!: number

  constructor(attrs: IVideoAsset & WithRef<VideoAsset>) {
    super(attrs)
  }
}

export interface AudioAssetSchema
  extends TemporalAssetSchema,
    VixenCore.PlayableSchema {}

export interface IAudioAsset extends ITemporalAsset, VixenCore.IPlayable {}

type AudioAssetSchemaType = z.Schema<
  AudioAsset,
  z.ZodTypeDef,
  AudioAssetSchema
>

export const AudioAssetSchema = z
  .lazy(() =>
    z.object({
      ref: GazelleRefSchema,
      url: z.string(),
      'content-type': z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      'distribution-control': z.enum(['unrestricted', 'onsite', 'admin-only']),
      'external-id': z.string().nullish(),
      duration: z.number().nullish(),
      'subtitle-languages': z.array(z.string()),
    }),
  )
  .transform(
    value => new AudioAsset(recase(value)),
  ) satisfies AudioAssetSchemaType

export class AudioAsset
  extends Entity<AudioAssetSchema>
  implements IAudioAsset
{
  static readonly typename = 'audio-asset'
  static readonly schema: AudioAssetSchemaType = AudioAssetSchema
  static readonly parse = AudioAssetSchema.parse
  static readonly fields = [
    'ref',
    'url',
    'content-type',
    'language',
    'copyright',
    'distribution-control',
    'external-id',
    'duration',
    'subtitle-languages',
  ]

  readonly typename = 'audio-asset'

  ref!: GazelleRef<AudioAsset>
  url!: string
  contentType!: string
  language?: string
  copyright?: string
  distributionControl!: 'unrestricted' | 'onsite' | 'admin-only'
  externalId?: string
  duration?: number
  subtitleLanguages!: Array<string>

  constructor(attrs: IAudioAsset & WithRef<AudioAsset>) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES & {
  'asset-group': IAssetGroup
  asset: IAsset
  'spatial-asset': ISpatialAsset
  'temporal-asset': ITemporalAsset
  'image-asset': IImageAsset
}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  picture: Picture,
  video: Video,
  audio: Audio,
  'raster-image-asset': RasterImageAsset,
  'vector-image-asset': VectorImageAsset,
  'video-asset': VideoAsset,
  'audio-asset': AudioAsset,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

export class Loader extends BundleLoader {
  static readonly ENTITIES = ENTITIES
  static readonly schemaVersion = '0.10.0'

  get<K extends keyof ENTITIES>(ref: {
    typename: K
    id: string
  }): ENTITIES[K] | undefined
  get<T extends ValueOf<ENTITIES> | ValueOf<INTERFACES> | Entity<any>>(
    ref: GazelleRef<T>,
  ): (T extends Entity<any> ? T : ReffableEntity<T> & T) | undefined
  get(ref: any) {
    return super.get(ref)
  }

  getAll<K extends keyof ENTITIES>(typename: K): Array<ENTITIES[K]>
  getAll<T extends ValueOf<ENTITIES>>(klass: GazelleEntityFactory<T>): Array<T>
  getAll(klass: any) {
    return super.getAll(klass)
  }

  getSingleton<K extends keyof ENTITIES>(typename: K): ENTITIES[K] | undefined
  getSingleton<T extends ValueOf<ENTITIES>>(
    klass: GazelleEntityFactory<T>,
  ): T | undefined
  getSingleton(klass: any) {
    return super.getSingleton(klass)
  }
}
