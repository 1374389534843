/** **************************************************************************************
 * Preemptive asset caching
 * ************************************************************************************** */

import { openDB } from 'idb'
import { isEqual } from 'radash'
import { Queue } from 'workbox-background-sync'

import {
  IAsset,
  RasterImageAsset,
  VectorImageAsset,
} from '../schemas/vixen-assets'
import { CachingAssetsDB } from '../storage/CachingAssetsDB'
import { CacheAssetsEvent } from '../types/worker'

/** Cache put assets in their respective cache */
function getCacheName(typename: string): string {
  switch (typename) {
    case RasterImageAsset.typename:
    case VectorImageAsset.typename:
      return 'image-cache'

    default:
      return 'media-cache'
  }
}

const cachingQueue = new Queue('caching-queue', {
  onSync: async ({ queue }) => {
    let entry: Awaited<ReturnType<typeof queue.shiftRequest>> & {
      metadata: { typename: string }
    }

    while ((entry = (await queue.shiftRequest()) as typeof entry)) {
      if (!navigator.onLine) {
        // If offline, re-queue the current entry and break the loop
        await queue.unshiftRequest(entry)
        break
      }

      try {
        const cacheName = getCacheName(entry.metadata.typename)
        const cache = await caches.open(cacheName)
        await cache.add(entry.request.url)
      } catch (error) {
        console.error('Replay failed for request', entry.request, error)
        // Re-queue the request for a later retry
        if (!navigator.onLine) {
          await queue.unshiftRequest(entry)
        }
      }
    }
  },
})

async function clearQueue(queueName: string): Promise<void> {
  const db = await openDB('workbox-background-sync')

  try {
    if (!db.objectStoreNames.contains(queueName)) {
      // The object store doesn't exist, so there's nothing to clear.
      return
    }

    const store = db.transaction(queueName, 'readwrite').objectStore(queueName)

    await store.clear()
  } finally {
    db.close()
  }
}

async function cacheAssetsSequentially(
  assets: Array<IAsset & { ref: { typename: string } }>,
): Promise<void> {
  for (const asset of assets) {
    const cacheName = getCacheName(asset.ref.typename)
    const cache = await caches.open(cacheName)

    try {
      const cachedResponse = await cache.match(asset.url)
      if (!cachedResponse) {
        await cache.add(asset.url)
      }
    } catch (err) {
      console.error(`Error caching asset: ${asset.url}`, err)
      // Add the request to the queue with metadata
      if (!navigator.onLine) {
        await cachingQueue.pushRequest({
          request: new Request(asset.url),
        })
      }
    }
  }
}

export async function cacheAssets(event: CacheAssetsEvent): Promise<void> {
  const { assets, cachingIds } = event

  if (!assets || !assets.length) {
    return
  }

  const db = new CachingAssetsDB()
  const previousCachingIds = await db.retrieveCachingIDs()

  const isSameIds = isEqual(new Set(cachingIds), new Set(previousCachingIds))

  if (isSameIds) {
    return
  }

  await clearQueue('caching-queue')

  await db.storeCachingIDs(cachingIds)
  await cacheAssetsSequentially(assets)
}
